import { Stack } from "@mui/material";
import { ElpromFileMeta } from "../API/apiElpromSnippets";
import ElpromFilesStageForm from "./ElpromFilesStageForm";

interface ElpromUploadFilesModalProps {
  filesMeta: ElpromFileMeta[];
  handleFetchTableData: () => Promise<void>;
}

const ElpromUploadFilesModal: React.FC<ElpromUploadFilesModalProps> = ({
  filesMeta,
  handleFetchTableData
}) => {

  return (
    <Stack spacing={3} sx={{mb: 0}}>
    <ElpromFilesStageForm
        filesMeta={filesMeta}
        handleFetchTableData={handleFetchTableData}
      />
    </Stack>
  );
};

export default ElpromUploadFilesModal;
