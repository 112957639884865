import {
  MRT_ColumnDef,
  MRT_DensityState,
  MRT_TableState,
  MRT_VisibilityState,
} from "material-react-table";
import { SerializedStyles } from "@emotion/react";
import { FormStatuses, SelectOption } from "../../../Global/Types/commonTypes";
import { TableAggregationFns, TableAggregationKey } from "./constructTableGrid";
import { camelCaseToTitleCase } from "../../../Global/Utils/commonFunctions";

export type TableGridDateTimeFilterModeOptions = "Before" | "After" | "Between dates";
export type TableGridDateTimeFilterValue = {
  filterMode: TableGridDateTimeFilterModeOptions;
  firstDate: Date | null;
  secondDate: Date | null;
};
export type TableGridDateType = "date" | "time" | "dateTime";
export type TableGridColumnDataTypes =
  | "string"
  | "number"
  | "boolean"
  | "button"
  | "dropdown"
  | TableGridDateType;
export type TableGridColAlign = "left" | "right" | "center";
export type TableGridColSymbol = {
  align: Omit<TableGridColAlign, "center">;
  value: string;
};

export type TableGridHandleSaveCellChanges = (
  rowIndex: number,
  colKey: string,
  value: any
) => void;
export interface TableGridProps<T extends Record<string, any>> {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  rows: T[];
  columns: MRT_ColumnDef<T>[];
  editMode?: TableGridPropsEditMode;
  setEditMode?: React.Dispatch<React.SetStateAction<boolean>>;
  configuration?: TableGridConfiguration;
  isStatic?: boolean;
  onRowClick?: (row: T) => void;
  loading?: boolean;
  onSave: (state: MRT_TableState<T>) => void;
  openConfigModal: () => void;
  tableState: MRT_TableState<T> | null;
  loadingConfigState: FormStatuses;
  hideConfigButton: boolean;
  virtualizedColsNumber?: number;
  handleRecalcAggregationsOnSearch: (rows: T[]) => void;
}

export type TableGridPropsEditMode = {
  handleDeleteRow: (rowIndex: number) => void;
  handleAddNewRow: () => void;
  handleSaveCellChanges: TableGridHandleSaveCellChanges;
};

export type TableGridConfiguration = {
  grouping?: string[];
  expandedGrouping?: true | undefined;
  hideAddButton?: boolean;
  hideDeleteRowButton?: boolean;
  density?: MRT_DensityState | undefined;
  hideMobilePagination?: boolean;
  hideMobileSearch?: boolean;
  disableHeader?: boolean;
  disablePagination?: boolean;
  initialRowsPerPage?: number;
  columnVisibility?: MRT_VisibilityState;
  enableStickyHeader?: boolean;
  maxTableHeight?: number;
  columnPinning?: {
    left: string[];
    right: string[];
  };
  pinnedRows?: {
    top: string[];
    bottom: string[];
  };
};

export const tableGridAddNewRowData = <T extends Record<string, any>>(
  column: MRT_ColumnDef<T>
) => {
  const key = (column.accessorKey || column.id) as string;
  // @ts-ignore
  const colType = column.meta?.type as string;
  let val: string | boolean | null = "";

  switch (colType) {
    case "date": {
      val = null;
      break;
    }
    case "dateTime": {
      val = null;
      break;
    }
    case "boolean": {
      val = false;
      break;
    }
    default: {
      val = "";
      break;
    }
  }

  return {
    [key]: val,
  };
};

export const tableAggregationOptions: SelectOption<TableAggregationKey>[] = Object.values(
  TableAggregationFns
).map((ope) => ({
  value: ope as TableAggregationKey,
  description: camelCaseToTitleCase(ope),
}));
