import { createBrowserRouter } from "react-router-dom";
import ROUTES_MAPPING from "./routesMapping";
import ConnectionsPage from "../../pages/Integrator/Connections/ConnectionsPage";
import OpcDaConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcDaConnectionInformationPage";
import OpcUaConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcUaConnectionInformationPage";
import MyDashboardsPage from "../../pages/MyDashboards/MyDashboardsPage";
import ExcellenceDashboardPage from "../../pages/MyDashboards/ExcellenceDashboardPage";
import MyFormsPage from "../../pages/MyForms/MyFormsPage";
import OperationsFormPage from "../../pages/MyForms/OperationsFormPage";
import OpcMQTTConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcMQTTConnectionInformationPage";
import LabelRecognitionPage from "../../pages/VisionControl/LabelRecognitionPage";
import Profile from "../../pages/Account/Profile";
import OpcModbusConnectionInformationPage from "../../pages/Integrator/Connections/Information/OpcModbusConnectionInformationPage";
import AuthLayout from "../AuthLayout";
import MaintenancePage from "../../pages/Maintenance/MachineParkPage";
import MaintenancePlan from "../../pages/Maintenance/MaintenancePlanPage";
import StocksPage from "../../pages/MyWarehouses/StocksPage";
import CodeScanningPage from "../../pages/ObjectTracking/CodeScanningPage";
import Rules from "../../pages/DataManager/Rules/Rules";
import MaintainLocationsPage from "../../pages/MyWarehouses/MaintainLocationsPage";
import CreateConnection from "../../pages/Integrator/CreateConnection";
import ProjectsPage from "../../pages/MyProjects/ProjectsPage";
import PageNotFound from "../../Components/SmallComponents/PageNotFound/PageNotFound";
import ShiftPlanning from "../../pages/ShiftPlanning/ShiftPlanning";
import MyProcesses from "../../pages/MyProcesses/MyProcesses";
import ProcessPage from "../../pages/MyProcesses/ProcessPage";
import ProductsPage from "../../pages/Products/ProductsPage";
import AnomalyDetectionPage from "../../pages/AnomalyDetection/AnomalyDetectionPage";
import ConditionMonitoringPage from "../../pages/Maintenance/ConditionMonitoringPage";
import MaintenanceKPIsPage from "../../pages/StandardKPIs/MaintenanceKPIsPage";
import OpExKPIsPage from "../../pages/StandardKPIs/OpExKPIsPage";
import OEELossesPage from "../../pages/OEE/OEELossesPage";
import OEEAssetsPage from "../../pages/OEE/OEEAssetsPage";
import OEECategoriesPage from "../../pages/OEE/OEECategoriesPage";
import KanBanBoardPage from "../../pages/KanbanWorkflows/KanBanBoardPage/KanBanBoardPage";
import OEEDashboardPage from "../../pages/OEE/OEEDashboardPage";
import TaskManager from "../../pages/TaskManager/TaskManagerPage";
import TaskDesignerPage from "../../pages/TaskManager/TaskDesignerPage";
import LongTermPlanning from "../../pages/LongTermPlanning/LongTermPlanning";
import WorkflowsPage from "../../pages/KanbanWorkflows/WorkflowsPage/WorkflowsPage";
import WorkflowSinglePage from "../../pages/KanbanWorkflows/WorkflowsPage/WorkflowSinglePage";
import PredictiveMaintenancePage from "../../pages/Maintenance/PredictiveMaintenancePage";
import MaterialsPage from "../../pages/Materials/MaterialsPage";
import PidCharts from "../../pages/PidCharts/PidWorkflows";
import SinglePidChart from "../../pages/PidCharts/SinglePidWorkflow";
import ComingSoonPage from "../../Components/SmallComponents/ComingSoonPage/ComingSoonPage";
import CurrenciesPage from "../../pages/Elprom/CurrenciesPage";
import ElpromKsoClientOrderPage from "../../pages/Elprom/ElpromKsoClientOrderPage";
import OrdersPage2 from "../../pages/Orders/OrdersPage2";
import { IS_ELPROM_ENV } from "../../envConfig";
import ProjectHubWrapper from "../../pages/Elprom/ProjectHubWrapper";

export default createBrowserRouter([
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      //  MY DASHBOARDS
      {
        path: "/",
        element: IS_ELPROM_ENV ? <ProjectHubWrapper /> : <MyDashboardsPage />,
      },
      // GIANT Analytics
      {
        path: ROUTES_MAPPING["Standard KPIs-Production KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Financial KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Sales KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Inventory KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Quality KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Energy KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Maintenance KPIs"],
        element: <MaintenanceKPIsPage />,
      },
      {
        path: ROUTES_MAPPING["Standard KPIs-Performance KPIs"],
        element: <OpExKPIsPage />,
      },
      {
        path: ROUTES_MAPPING["Excellence"],
        element: <MyDashboardsPage />,
      },
      {
        path: ROUTES_MAPPING.excellenceDashboard,
        element: <ExcellenceDashboardPage />,
      },
      {
        path: ROUTES_MAPPING["Advanced Analytics"],
        element: <MyDashboardsPage />,
      },
      {
        path: ROUTES_MAPPING.advancedAnalyticsDashboard,
        element: <ExcellenceDashboardPage />,
      },
      {
        path: ROUTES_MAPPING["DoE"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Facilitator (SPC)"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Anomaly Detection"],
        element: <AnomalyDetectionPage />,
      },
      {
        path: ROUTES_MAPPING["Text Recognition (OCR)"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Vision Control"],
        element: <LabelRecognitionPage />,
      },

      // GIANT Planning
      // Project Hub
      {
        path: ROUTES_MAPPING["Project Hub-KPIs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Project Hub-Import"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Project Hub-Projects"],
        element: <ProjectsPage />,
      },
      // Sales and Orders
      {
        path: ROUTES_MAPPING["Sales and Orders-Products"],
        element: <ProductsPage />,
      },
      {
        path: ROUTES_MAPPING["Sales and Orders-Pricing"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Sales and Orders-Customer Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Sales and Orders-Quotes and Orders"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Sales and Orders-Shipping Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Sales and Orders-Sales Reports"],
        element: <ComingSoonPage />,
      },
      // Warehouse
      {
        path: ROUTES_MAPPING["Warehouse-Stock Control"],
        element: <StocksPage />,
      },
      {
        path: ROUTES_MAPPING["Warehouse-Locations"],
        element: <MaintainLocationsPage />,
      },
      {
        path: ROUTES_MAPPING["Warehouse-Inventory Counting"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Warehouse-Real-Time Tracking"],
        element: <ComingSoonPage />,
      },
      // Materials and Procurement
      {
        path: ROUTES_MAPPING["Materials and Procurement-Purchase Orders"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Purchase Requisitions"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Goods Receipt"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Materials Management"],
        element: <MaterialsPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Supplier Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Purchasing Details-Prices"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Purchasing Details-Special"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Materials and Procurement-Purchasing Details-Conditions"],
        element: <ComingSoonPage />,
      },
      // Human Resources
      {
        path: ROUTES_MAPPING["Human Resources-Personnel Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Human Resources-Shift Planning"],
        element: <ShiftPlanning />,
      },
      {
        path: ROUTES_MAPPING["Human Resources-Work Plans"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Human Resources-Time Tracking"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Human Resources-Employee Qualifications"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Human Resources-Payroll"],
        element: <ComingSoonPage />,
      },
      // Resource Planning
      {
        path: ROUTES_MAPPING["Resource Planning-Long-Term Planning"],
        element: <LongTermPlanning />,
      },
      {
        path: ROUTES_MAPPING["Resource Planning-Detailed Planning"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Resource Planning-What-if-Simulation"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Resource Planning-Capacities"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Resource Planning-Bookings"],
        element: <ComingSoonPage />,
      },
      // Lifecycle
      {
        path: ROUTES_MAPPING["Lifecycle-eBOM"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Lifecycle-mBOM"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Lifecycle-Product Tracking-Production History"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Lifecycle-Product Tracking-Quality History"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Lifecycle-Product Tracking-Movements"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Lifecycle-Object Tracking"],
        element: <CodeScanningPage />,
      },
      // Finance
      {
        path: ROUTES_MAPPING["Finance-Accounting"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Cost Controlling"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Financial Reports"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Budgeting and Forecasting"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Payment and Receivables"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Invoices"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Finance-Currencies"],
        element: <CurrenciesPage />,
      },

      // GIANT Operations Suite
      // OEE
      {
        path: ROUTES_MAPPING["OEE-Dashboards"],
        element: <OEEDashboardPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Losses"],
        element: <OEELossesPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Assets"],
        element: <OEEAssetsPage />,
      },
      {
        path: ROUTES_MAPPING["OEE-Categories"],
        element: <OEECategoriesPage />,
      },
      // Energy Management
      {
        path: ROUTES_MAPPING["Energy Management-Energy Monitoring"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Energy Analytics"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Energy Optimization"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Energy Cost Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Energy Forecasting"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Energy Consumption"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Energy Management-Settings"],
        element: <ComingSoonPage />,
      },
      // Quality Management
      {
        path: ROUTES_MAPPING["Quality Management-Quality Inspection Plan"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-Certificates"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-Quality Parameters"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-Quality Inspection Plan"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-Quality Reports"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-Traceability and Conformance"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Quality Management-NCM"],
        element: <ComingSoonPage />,
      },
      // Organizer
      {
        path: ROUTES_MAPPING["Organizer-Task Designer"],
        element: <TaskDesignerPage />,
      },
      {
        path: ROUTES_MAPPING["Organizer-Work Instructions"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Organizer-Task Manager"],
        element: <TaskManager />,
      },
      {
        path: ROUTES_MAPPING["Organizer-Kanban Board"],
        element: <KanBanBoardPage />,
      },
      {
        path: ROUTES_MAPPING["Organizer-Workflow Modeler"],
        element: <WorkflowsPage />,
      },
      {
        path: ROUTES_MAPPING["Organizer-Measures Tracker"],
        element: <ComingSoonPage />,
      },
      // Operational Excellence
      {
        path: ROUTES_MAPPING["Operational Excellence-Production Orders"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Operational Excellence-Production Planning"],
        element: <OrdersPage2 />,
      },
      {
        path: ROUTES_MAPPING["Operational Excellence-Production Control"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Operational Excellence-Resource Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Operational Excellence-Production Progress"],
        element: <ComingSoonPage />,
      },
      // Forms and Flows
      {
        path: ROUTES_MAPPING["Forms and Flows-Forms Designer"],
        element: <MyFormsPage />,
      },
      {
        path: ROUTES_MAPPING.operationsForm,
        element: <OperationsFormPage />,
      },
      {
        path: ROUTES_MAPPING["Forms and Flows-Workflows"],
        element: <MyProcesses />,
      },
      {
        path: ROUTES_MAPPING.process,
        element: <ProcessPage />,
      },
      {
        path: ROUTES_MAPPING.workflow,
        element: <WorkflowSinglePage />,
      },
      {
        path: ROUTES_MAPPING["Forms and Flows-Work Manager"],
        element: <ComingSoonPage />,
      },
      // Maintenance
      {
        path: ROUTES_MAPPING["Maintenance-Machine Park"],
        element: <MaintenancePage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Maintenance Plan"],
        element: <MaintenancePlan />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Asset Monitoring"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Maintenance Logs"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Spare Parts Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Condition Monitoring"],
        element: <ConditionMonitoringPage />,
      },
      {
        path: ROUTES_MAPPING["Maintenance-Predictive Maintenance"],
        element: <PredictiveMaintenancePage />,
      },
      // Layouts
      {
        path: ROUTES_MAPPING["Layouts-P&ID"],
        element: <PidCharts />,
      },
      {
        path: ROUTES_MAPPING.pidChart,
        element: <SinglePidChart />,
      },
      {
        path: ROUTES_MAPPING["Layouts-Plant Overview"],
        element: <ComingSoonPage />,
      },

      // GIANT Toolbox Manager
      // Integrator
      {
        path: ROUTES_MAPPING["Integrator-Data Sources"],
        element: <CreateConnection />,
      },
      {
        path: ROUTES_MAPPING["Integrator-Connections"],
        element: <ConnectionsPage />,
      },
      {
        path: ROUTES_MAPPING["Integrator-Connections-opc-da-information"],
        element: <OpcDaConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["Integrator-Connections-opc-ua-information"],
        element: <OpcUaConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["Integrator-Connections-opc-mqtt-information"],
        element: <OpcMQTTConnectionInformationPage />,
      },
      {
        path: ROUTES_MAPPING["Integrator-Connections-opc-modbus-information"],
        element: <OpcModbusConnectionInformationPage />,
      },
      // Data Manager
      {
        path: ROUTES_MAPPING["Data Manager-Entity Definition"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Forms and Manual Entries"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Parameters Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Data Engineering"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Rules"],
        element: <Rules />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Actions"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Data Manager-Notifications"],
        element: <ComingSoonPage />,
      },
      // User Manager
      {
        path: ROUTES_MAPPING["User Manager-User and Role Management"],
        element: <ComingSoonPage />,
      },
      // Admin Panel
      {
        path: ROUTES_MAPPING["Admin Panel-User Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Admin Panel-Role Management"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Admin Panel-System Configuration"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Admin Panel-Security and Access Control"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Admin Panel-Process Criticality"],
        element: <ComingSoonPage />,
      },
      {
        path: ROUTES_MAPPING["Admin Panel-Audit Trail"],
        element: <ComingSoonPage />,
      },
      // Profile
      {
        path: ROUTES_MAPPING.accountProfile,
        element: <Profile />,
      },
      // Elprom
      {
        path: ROUTES_MAPPING["currencies"],
        element: <CurrenciesPage />,
      },
      {
        path: ROUTES_MAPPING["projectHubData"],
        element: <ProjectHubWrapper />,
      },
      {
        path: ROUTES_MAPPING["projectHubClientOrder"],
        element: <ProjectHubWrapper />,
      },
      {
        path: ROUTES_MAPPING["projectHubKsoClientOrder"],
        element: <ElpromKsoClientOrderPage />,
      },
      {
        path: ROUTES_MAPPING["projectHubCompareCollateral"],
        element: <ProjectHubWrapper />,
      },
      // CATCH ALL PATHS
      {
        path: "/*",
        element: <PageNotFound />,
      },
    ],
  },
]);
