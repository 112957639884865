import {
  AutocompleteRenderInputParams,
  Avatar,
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import callApi from "../../../Api/callApi";
import {
  AutocompleteOption,
  FormStatuses,
  SelectOption,
} from "../../../Global/Types/commonTypes";
import { useAuthedContext } from "../../../context/AuthContext";
import { useEffect, useState } from "react";
import cssLayoutStyles from "../../../Global/Styles/layout";
import cssSpacingStyles from "../../../Global/Styles/spacing";
import { useLanguageContext } from "../../../context/LanguageContext";
import Autocomplete from "../../../Components/MaterialUI/FormFields/Autocomplete";
import Button from "../../../Components/MaterialUI/Button";
import { getQueryAllUserDeputy, getQueryDeputyUsersSearchList, getQueryUserDeputy } from "../../../Api/UserDeputy/apiUserDeputyGetQueries";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { formatDateAndTime } from "../../../Global/Utils/commonFunctions";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import Modal from "../../../Components/MaterialUI/Modal";
import cssFontStyles from "../../../Global/Styles/font";
import DatePicker from "../../../Components/MaterialUI/DateTimePickers/DatePicker";
import Alert from "../../../Components/MaterialUI/Alert";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {
  deleteQueryUserDeputyAccessControl,
  postQueryUserDeputyAccessControl,
} from "../../../Api/UserDeputy/apiUserDeputyPostQueries";
import { PostQueryUserDeputyAccessControlInput } from "../../../Api/UserDeputy/apiUserDeputyInput";
import { DeputySearchListEntry, GetQueryAllUserDeputySnippet, getQueryDeputyUsersSearchListSnipper } from "../../../Api/UserDeputy/apiUserDeputySnippets";

type DeputyUser = {
  username: string;
  firstName: string;
  lastName: string;
  assignedOn: Date;
  deputyUntil: Date;
  currentlyAssigned: boolean;
};

type ModalType = {
  type: "Deputy History" | "Remove Deputy";
  toRemove?: {
    name: string;
    username: string;
  };
  history?: any;
} | null;

const DeputyList: React.FC<{}> = () => {
  const theme = useTheme();
  const styles = { ...cssLayoutStyles, ...cssSpacingStyles(theme), ...cssFontStyles };
  const { t } = useLanguageContext();
  const { setAuthedUser } = useAuthedContext();

  const [allDeputies, setAllDeputies] = useState<DeputyUser[]>([]);
  const [modalType, setModalType] = useState<ModalType>(null);
  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);

  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [usersOptions, setUsersOptions] = useState<SelectOption[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [selectedUserOption, setSelectedUserOption] = useState<AutocompleteOption | null>(
    null
  );
  const [newDeputyFrom, setNewDeputyFrom] = useState<Date | null>(null);
  const [newDeputyUntil, setNewDeputyUntil] = useState<Date | null>(null);

  const [deputiesFound, setDeputiesFound] = useState<DeputySearchListEntry[]>([]);

  const MIN_LENGTH_FOR_SEARCH = 3;

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        setAlertMessage(t("Fetching Data"));
        await getAllDeputies();
      } catch (err) {
        console.log("err ", err);
      }
      setPageStatus(null);
      setAlertMessage(null);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
        const options: SelectOption[] = deputiesFound.map((item) => ({
          value: item.id,
          description: `${item.name}`,
        }));

        setUsersOptions(options);
    })();
  }, [deputiesFound]);

  useEffect(() => {
    (async () => {
      try {
        if(searchTerm.length < MIN_LENGTH_FOR_SEARCH) {
          return;
        }
  
        const deputiesSearchListRes = await callApi<getQueryDeputyUsersSearchListSnipper>({
          query: getQueryDeputyUsersSearchList(searchTerm),
          auth: { setAuthedUser },
        });
  
        if("users" in deputiesSearchListRes) {
          setDeputiesFound(deputiesSearchListRes.users)
        }
      } catch (err) {
        console.log("No results found")
      }
  })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[searchTerm])

  const getAllDeputies = async () => {
    const allSavedDeputies = await callApi<GetQueryAllUserDeputySnippet>({
      query: getQueryAllUserDeputy(),
      auth: { setAuthedUser },
    });

    const transformedDeputies = await transformDeputies(allSavedDeputies);

    setAllDeputies(transformedDeputies);
  };

  const transformDeputies = async (data: GetQueryAllUserDeputySnippet): Promise<DeputyUser[]> => {   
    const promises = data.map(async (item) => {
      try {
        const deputy = await callApi<any>({
          query: getQueryUserDeputy(item.deputy_user_id),
          auth: { setAuthedUser }
        })

        return {
          username: item.id,
          assignedOn: new Date(item.start_date),
          deputyUntil: new Date(item.end_date),
          currentlyAssigned: true,
          firstName: deputy.given_name,
          lastName: deputy.last_name,
        };
      } catch (err) {
        console.log("getUserDeputy err ", err);
        return null
      }
          
    })

    const result: (DeputyUser | null)[] = await Promise.all(promises);
    return result.filter(result => result !== null) as DeputyUser[]
  };

  const handleSearchChange = (inputValue: string, reason: string | undefined) => {
    setSearchTerm(inputValue)

    if (inputValue.length < MIN_LENGTH_FOR_SEARCH || reason !== "input") {
      if (searchOpen) setSearchOpen(false);
    } else {
      if (!searchOpen) setSearchOpen(true);
    }
  };

  const handleAssignDeputy = async () => {
    try {
      if (!selectedUserOption?.value) {
        setAlertMessage(t("You must select a user"));
        setPageStatus("warning");
        return;
      }
      if (!newDeputyFrom) {
        setAlertMessage(t("You must select a from date"));
        setPageStatus("warning");
        return;
      }
      if (!newDeputyUntil) {
        setAlertMessage(t("You must select a until date"));
        setPageStatus("warning");
        return;
      }
      setAlertMessage(t("Assigning deputy..."));
      setPageStatus("loading");

      const input: PostQueryUserDeputyAccessControlInput = {
        deputy_user_id: selectedUserOption.value,
        end_date: newDeputyUntil!.toISOString(),
        start_date: newDeputyFrom!.toISOString(),
      };
      await callApi<any>({
        query: postQueryUserDeputyAccessControl(input),
        auth: { setAuthedUser },
      });
      await getAllDeputies();
    } catch (err) {
      console.log("handleAddUser err ", err);
    }
    setPageStatus(null);
    setAlertMessage(null);
  };

  const handleRemoveDeputy = async () => {
    try {
      if (!modalType?.toRemove) {
        return;
      }

      await callApi<any>({
        query: deleteQueryUserDeputyAccessControl(modalType.toRemove.username),
        auth: { setAuthedUser },
      });
      await getAllDeputies();
      setModalType(null);
    } catch (err) {
      console.log("handleRemoveDeputy err ", err);
    }
  };
  
  return (
    <Box component="div">
      <Typography css={styles.contentBreak} variant="h2" textAlign="center">
        {t("Appoint Deputies")}
      </Typography>

      <Box
        css={[styles.width100, styles.widthLimit60, styles.marginHorizontalAuto]}
        component="div"
      >
        <Grid css={styles.sectionBreak} spacing={3} container>
          <Grid item xs={6} md={4}>
            <Autocomplete
              open={searchOpen}
              css={styles.width100}
              options={usersOptions}
              label={t("User")}
              value={selectedUserOption}
              renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                  {...params}
                  label={t("User")}
                  placeholder={`Type at least ${MIN_LENGTH_FOR_SEARCH} letters...`}
                />
              )}
              handleOnChange={(val: AutocompleteOption) => {
                setSelectedUserOption(val);
              }}
              onInputChange={(_, inputValue, reason) => {
                handleSearchChange(inputValue, reason);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                }
              }}
              onClose={() => setSearchOpen(false)}
              disabled={pageStatus === "loading"}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DatePicker
              css={styles.width100}
              label={t("Assign Deputy From")}
              onChange={(value) => setNewDeputyFrom(value)}
              value={newDeputyFrom}
              maxDate={newDeputyUntil || undefined}
              disabled={pageStatus === "loading"}
              disablePast
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <DatePicker
              css={styles.width100}
              label={t("Assign Deputy Until")}
              onChange={(value) => setNewDeputyUntil(value)}
              value={newDeputyUntil}
              minDate={newDeputyFrom || undefined}
              disabled={pageStatus === "loading"}
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <Stack
              css={[styles.height100, styles.width100]}
              alignItems="center"
              justifyContent="center"
            >
              <Button
                css={styles.width100}
                variant="outlined"
                color="primary"
                onClick={handleAssignDeputy}
                disabled={pageStatus === "loading"}
              >
                Assign Deputy
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>

      <Alert
        css={[
          styles.labelBreak,
          styles.width100,
          styles.widthLimit20,
          styles.marginHorizontalAuto,
        ]}
        severity={pageStatus}
        message={alertMessage}
        showAlert={!!alertMessage}
      />

      <Typography css={styles.contentBreak} variant="h4" textAlign="center">
        {t("Assigned Deputies")}
      </Typography>

      {pageStatus === "loading" ? null : (
        <Box sx={{ overflowY: "auto", maxHeight: "150px" }} component="div">
          <List
            css={[styles.width100, styles.widthLimit25, styles.marginHorizontalAuto]}
            dense
          >
            {allDeputies.map((user) => (
              <ListItem
                key={user.username}
                alignItems="flex-start"
                secondaryAction={
                  <Stack
                    spacing={0.1}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {user.currentlyAssigned ? (
                      <IconButton edge="end" aria-label="delete" color="error">
                        <DeleteOutlineIcon
                          onClick={() =>
                            setModalType({
                              type: "Remove Deputy",
                              toRemove: {
                                name: `${user.firstName}  ${user.lastName}`,
                                username: user.username,
                              },
                            })
                          }
                        />
                      </IconButton>
                    ) : null}
                    {/* <IconButton edge="end" aria-label="delete" color="info">
                    <HistoryIcon
                      onClick={() =>
                        setModalType({
                          type: "Deputy History",
                          history: true,
                        })
                      }
                    />
                  </IconButton> */}
                  </Stack>
                }
              >
                <ListItemAvatar>
                  {/* <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" /> */}
                  <Avatar>
                    <AccountCircleIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${user.firstName}  ${user.lastName}`}
                  secondary={
                    user.currentlyAssigned ? (
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.secondary", display: "inline" }}
                        >
                          {formatDateAndTime(user.assignedOn, "date")}
                        </Typography>
                        <HorizontalRuleIcon
                          sx={{ color: theme.palette.text.secondary }}
                        />
                        <Typography
                          component="span"
                          variant="body2"
                          sx={{ color: "text.secondary", display: "inline" }}
                        >
                          {formatDateAndTime(user.deputyUntil, "date")}
                        </Typography>
                      </Stack>
                    ) : (
                      <Typography
                        component="span"
                        variant="body2"
                        sx={{ color: "text.secondary", display: "inline" }}
                      >
                        {t("Not currently assigned")}
                      </Typography>
                    )
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      )}

      <Modal
        open={!!modalType}
        onClose={() => setModalType(null)}
        fullWidth
        maxWidth={"sm"}
        label={modalType?.type || ""}
      >
        {modalType?.type === "Remove Deputy" ? (
          <Stack spacing={3} alignItems="center" justifyContent="center">
            <Typography variant="body1">
              Are you sure you want to remove{" "}
              <span css={styles.bolderText}>{modalType.toRemove?.name}</span> as a deputy?
            </Typography>
            <Button onClick={handleRemoveDeputy} color="error">
              Confirm Removal
            </Button>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default DeputyList;