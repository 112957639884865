import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Divider,
  Box,
  Theme,
  IconButton,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import { SerializedStyles, css } from "@emotion/react";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { Link, useLocation } from "react-router-dom";
import useTheme from "@mui/material/styles/useTheme";
import { LeftNavList, LeftNavSingleItem } from "../layoutVariables";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import Collapse from "../../Components/MaterialUI/Collapse";
import cssComponentsStyles from "../../Global/Styles/components";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import PushPinIcon from "@mui/icons-material/PushPin";
import CloseIcon from "@mui/icons-material/Close";
import { FormStatuses } from "../../Global/Types/commonTypes";
import Alert from "../../Components/MaterialUI/Alert";
import { IS_ELPROM_ENV } from "../../envConfig";

const cssStyles = (theme: Theme) => ({
  listSubheaderContainer: css({
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.default,
  }),
  editIconButton: css({
    marginRight: theme.spacing(1),
    backgroundColor: "inherit",
  }),
  linkItem: css({
    width: `calc(100% - ${theme.spacing(2)})`,
    position: "relative",
  }),
  itemDivider: css({
    position: "absolute",
    left: theme.spacing(1),
    top: theme.spacing(6),
    height: `calc(100% - ${theme.spacing(7)})`,
  }),
  openedParent: css({
    background:
      theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[900],
    borderRadius: theme.shape.borderRadius,
  }),
  openedChild: css({
    color: theme.palette.primary.main,
  }),
  activeItem: css({
    background: theme.palette.mode === "light" ? "#E8E8E8" : "#313131",
    borderRadius: theme.shape.borderRadius,
  }),
  pinIcon: css({
    padding: theme.spacing(0.5),
    marginRight: "0.2px",
    transform: "rotate(180deg)",
  }),
});


interface LeftNavListMenuProps {
  css?: SerializedStyles[] | SerializedStyles;
  className?: string;
  navList: LeftNavList;
  listTitle: string;
  isEditFavouritesMode: boolean;
  onEditClick?: () => void;
  toggleFavourite: (item: LeftNavSingleItem) => void;
  favourites: string[];
  formStatus: FormStatuses;
  alertMessage: string | null;
}

const LeftNavListMenu: React.FC<LeftNavListMenuProps> = ({
  className,
  navList,
  listTitle,
  isEditFavouritesMode,
  onEditClick,
  toggleFavourite,
  favourites,
  formStatus,
  alertMessage,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
  };

  return (
    <List className={className} component="nav">
      {IS_ELPROM_ENV ? null : (
        <Box component="div" css={[styles.listSubheaderContainer]}>
          <ListSubheader component="div">{listTitle}</ListSubheader>
          {listTitle === "My Favourites" ? (
            <Tooltip title={isEditFavouritesMode ? "Save Favourites" : "Edit Favourites"}>
              <IconButton onClick={onEditClick} css={styles.editIconButton}>
                <EditNoteOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : null}
        </Box>
      )}
      <>
        {!IS_ELPROM_ENV && listTitle === "My Favourites" ? (
          <Alert
            message={alertMessage || ""}
            showAlert={!!alertMessage}
            severity={formStatus}
          />
        ) : null}

        {navList.map((item, index) => (
          <NavItem
            key={`parent-item${index}-${isEditFavouritesMode}`}
            text={item.text}
            url={item.url}
            nested={item.nested}
            Icon={item.Icon}
            disabled={item.disabled}
            isParent
            isEditFavouritesMode={isEditFavouritesMode}
            toggleFavourite={toggleFavourite}
            listTitle={listTitle}
            favourites={favourites}
            formStatus={formStatus}
          />
        ))}
      </>
    </List>
  );
};

export default LeftNavListMenu;

interface NavItemProps extends LeftNavSingleItem {
  isParent?: boolean;
  isEditFavouritesMode: boolean;
  toggleFavourite: (item: LeftNavSingleItem) => void;
  listTitle?: string;
  favourites: string[];
  formStatus: FormStatuses;
}

const NavItem: React.FC<NavItemProps> = ({
  text,
  url,
  nested,
  isParent,
  Icon,
  disabled,
  isEditFavouritesMode,
  toggleFavourite,
  listTitle,
  favourites,
  formStatus,
}) => {
  const theme = useTheme();
  const styles = {
    ...cssStyles(theme),
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssComponentsStyles(theme),
  };
  const [open, setOpen] = useState<boolean>(false);
  const location = useLocation();
  const isActive = url && location.pathname === url;
  const isFavourite = favourites.includes(text);

  const handleItemIconClick = () => {
    setOpen(!open);
  };

  const handlePinIconClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    toggleFavourite({ text, url, nested, Icon, disabled });
  };

  const itemIconButtonProps = {
    ...(url ? { component: Link, to: url } : { onClick: handleItemIconClick }),
  };

  const isNestedActive =
    nested && nested.some((nestedItem) => nestedItem.url === location.pathname);

  return (
    <Box
      component="div"
      css={[styles.linkItem, styles.leftSpacer2, isActive && styles.activeItem]}
    >
      {open && isParent ? (
        <Divider css={styles.itemDivider} orientation="vertical" />
      ) : null}
      <Box component="div" css={styles.width100}>
        <ListItemButton
          css={[
            styles.width100,
            styles.hoverItem,
            isParent && open && isNestedActive && styles.openedParent,
          ]}
          {...itemIconButtonProps}
          disabled={disabled}
        >
          <ListItemIcon>
            <Icon />
          </ListItemIcon>
          <ListItemText css={[!isParent && open && styles.openedChild]} primary={text} />
          {nested ? <>{open ? <ExpandLess /> : <ExpandMore />}</> : null}

          {isEditFavouritesMode &&
          !nested &&
          !(isFavourite && listTitle !== "My Favourites") ? (
            <>
              <IconButton
                css={styles.pinIcon}
                onClick={(e) => {
                  handlePinIconClick(e);
                }}
                edge="end"
                disabled={formStatus === "loading"}
              >
                {listTitle === "My Favourites" ? (
                  <CloseIcon fontSize="small" />
                ) : (
                  <PushPinIcon fontSize="small" />
                )}
              </IconButton>
            </>
          ) : null}
        </ListItemButton>

        {nested ? (
          <Collapse in={open} timeout="auto" unmountOnExit>
            {nested.map((nestedItem, index) => (
              <NavItem
                key={`parent-item-${nestedItem.url}-${index}`}
                text={nestedItem.text}
                url={nestedItem.url}
                nested={nestedItem.nested}
                Icon={nestedItem.Icon}
                disabled={nestedItem.disabled}
                isEditFavouritesMode={isEditFavouritesMode}
                toggleFavourite={toggleFavourite}
                favourites={favourites}
                formStatus={formStatus}
              />
            ))}
          </Collapse>
        ) : null}
      </Box>
    </Box>
  );
};
