import { object } from "yup";
import { Stack, Typography } from "@mui/material";
import TextField from "../Components/MaterialUI/FormFields/TextFields";
import Alert from "../Components/MaterialUI/Alert";
import Button from "../Components/MaterialUI/Button";
import cssLayoutStyles from "../Global/Styles/layout";
import { useEffect, useState } from "react";
import { FormStatuses } from "../Global/Types/commonTypes";
import { Formik } from "formik";
import cssSpacingStyles from "../Global/Styles/spacing";
import useTheme from "@mui/material/styles/useTheme";
import { Link, useLocation, useNavigate } from "react-router-dom";
import callApi from "../Api/callApi";
import { YUP_EMAIL, YUP_PASSWORD_STRING } from "../Global/Constants/yupConstants";
import { PostQueryLoginInput } from "../Api/Auth/apiAuthInputs";
import { postQueryLogin } from "../Api/Auth/apiAuthPostQueries";
import { PostQueryLoginSnippet } from "../Api/Auth/apiAuthSnippets";
import { SetCookieParams, setCookie } from "../Global/Utils/commonFunctions";
import jwtDecode from "jwt-decode";
import { COOKIE_REFRESH_TOKEN } from "../Global/Constants/commonConstants";
import { DecodedJWTToken } from "../context/authContextTypes";
import { useAuthedContext } from "../context/AuthContext";
import AuthPagesLayout from "../Components/AuthPageComponents/AuthPageLayout";
import cssFontStyles from "../Global/Styles/font";
import PasswordToggleIcon from "../Components/AuthPageComponents/PasswordToggleIcon";

const fieldValidation = object({
  email: YUP_EMAIL,
  password: YUP_PASSWORD_STRING,
});

type LoginFormValues = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const theme = useTheme();
  const styles = {
    ...cssLayoutStyles,
    ...cssSpacingStyles(theme),
    ...cssFontStyles,
  };
  const [formStatus, setFormStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { setUserSignedIn } = useAuthedContext();
  const location = useLocation();
  const navigate = useNavigate();
  const [hasReloaded, setHasReloaded] = useState<boolean>(false);

  useEffect(() => {
    if (location.state?.fromReset && !hasReloaded) {
      window.location.reload();
      setHasReloaded(true);
      navigate("/", { replace: true });
    }
  }, [location.state?.fromReset, hasReloaded, navigate]);

  const initialValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const handleFormSubmit = async (values: LoginFormValues) => {
    try {
      setFormStatus("loading");
      setAlertMessage("Loading...");

      const input: PostQueryLoginInput = {
        username: values.email,
        password: values.password,
      };
      const user = await callApi<PostQueryLoginSnippet>({
        query: postQueryLogin(input),
        auth: null,
      });
      if (user.response) {
        const refresh_token = user.response.refresh_token;
        const decodedRefreshToken: DecodedJWTToken = jwtDecode(refresh_token);

        console.log(user.response)
        if (user.response.force_new_password) {
          console.log("Inside navigate to reset password page");
          // redirect to reset password page with refresh_token as query param token
          navigate({pathname: "/forgotten-password", search: `?token=${refresh_token}`});
          // dont do the other code
          return;
        }
        // save the refresh_token as a cookie
        const refreshCookie: SetCookieParams = {
          name: COOKIE_REFRESH_TOKEN,
          value: refresh_token,
          exp: decodedRefreshToken.exp,
          sameSite: "strict",
          secure: true,
        };
        setCookie(refreshCookie);

        // setUserSignedInFlag to true which will trigger authUser sign in logic
        setUserSignedIn(true);

        setFormStatus("success");
        setAlertMessage("Success");
      } else if (user.detail) {
        throw new Error(user.detail);
      }
    } catch (err) {
      console.log("Login form, handleFormSubmit() err: ", err.message);
      setFormStatus("error");
      setAlertMessage(err.message);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleFormSubmit}
      validationSchema={fieldValidation}
    >
      {({ handleSubmit, handleChange, touched, errors, values }) => (
        <form css={styles.marginHorizontalAuto} onSubmit={handleSubmit}>
          <AuthPagesLayout>
            <Typography align="center" mb={2} variant="h1">
              Sign in
            </Typography>
            <TextField
              name="email"
              label="Email"
              error={touched["email"] && !!errors["email"]}
              helperText={touched["email"] && errors["email"]}
              onChange={handleChange}
              value={values.email}
            />
            <TextField
              name="password"
              label="Password"
              error={touched["password"] && !!errors["password"]}
              helperText={touched["password"] && errors["password"]}
              onChange={handleChange}
              type={showPassword ? "text" : "password"}
              value={values.password}
              InputProps={{
                endAdornment: values.password && (
                  <PasswordToggleIcon
                    showPassword={showPassword}
                    setShowPassword={setShowPassword}
                  />
                ),
              }}
            />
            <Button
              css={[styles.width100, styles.widthLimit10]}
              type="submit"
              loading={formStatus === "loading"}
            >
              Sign in
            </Button>
            <Alert
              message={alertMessage || ""}
              showAlert={!!alertMessage}
              severity={formStatus}
            />
            <Stack direction="column" alignItems="center">
              {/* <Link
                css={styles.boldText}
                to={"/sign-up"}
                style={{ color: theme.palette.primary.main }}
              >
                Don't have an account? Sign Up
              </Link> */}
              <Link
                css={styles.boldText}
                to={"/forgotten-password"}
                style={{ color: theme.palette.primary.main }}
              >
                Forgot password?
              </Link>
            </Stack>
          </AuthPagesLayout>
        </form>
      )}
    </Formik>
  );
};

export default Login;
