import { TableGridColumnDataTypes } from "../../../Components/SmallComponents/TableGrid/tableGridUtils";

export type ElpromCurrency = {
  code: string;
  name: string;
  rate: number;
  auto_update: boolean;
  id: string;
  last_updated: string;
};

export type ElpromFileTypes = "prices" | "supco" | "kso" | "priorities";
export type ElpromFileMeta = {
  file_name: string;
  file_type: ElpromFileTypes;
  file_url: string;
  id: string;
  uploaded_at: string;
};

export type ElpromTableDataCols = {
  id: string;
  name: string;
  type: TableGridColumnDataTypes;
  alignment?: "right" | "left";
  decimal_points?: number;
  symbol?: string;
  symbol_alignment?: "after" | "before";
};

export type ElpromTableData = {
  rows: Record<string, any>[];
  columns: ElpromTableDataCols[];
};

export type ElpromTableDataDiscrepancies = {
  rows: Record<string, any>[];
  columns: ElpromTableDataCols[];
  newcoming_material_codes: string[];
};

export const TAB_NAMES = ["collateral_summary","supco", "kso", "prices", "prices_po" , "collateral_not_distributed", "collateral_compare"]
export type TabName = typeof TAB_NAMES[number];
export type UserAccessKeys = TabName | "upload_files";
export type ElpromUserAccessPermisionData = {
  [key in UserAccessKeys]: boolean;
} & {
  role_id: string;
};