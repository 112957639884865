import {
  Box,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DownloadIcon from "@mui/icons-material/Download";
import Alert from "../../Components/MaterialUI/Alert";
import cssLayoutStyles from "../../Global/Styles/layout";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useEffect, useState } from "react";
import { FormStatuses, SelectOption } from "../../Global/Types/commonTypes";
import { useAuthedContext } from "../../context/AuthContext";
import Modal from "../../Components/MaterialUI/Modal";
import callApi from "../../Api/callApi";
import {
  getQueryElpromUserAccess,
  getQueryElpromCollateralNotDistributed,
  getQueryElpromCompareData,
  getQueryElpromDates,
  getQueryElpromDetails,
  getQueryElpromFilesMetadata,
  getQueryElpromKso,
  getQueryElpromPrices,
  getQueryElpromPricesPo,
  getQueryElpromSupco,
} from "./API/apiElpromGetQueries";
import {
  ElpromFileMeta,
  ElpromUserAccessPermisionData,
  ElpromTableData,
  ElpromTableDataCols,
  TAB_NAMES,
  TabName,
} from "./API/apiElpromSnippets";
import cssFontStyles from "../../Global/Styles/font";
import ElpromDownloadFilesModal from "./Components/ElpromDownloadFilesModal";
import ElpromUploadFilesModal from "./Components/ElpromUploadFilesModal";
import {
  calcTableFooterAggregations,
  TableAggregationFns,
  TableCellsConfig,
  TableDynamicCondition,
  TableGridColumnSchema,
} from "../../Components/SmallComponents/TableGrid/constructTableGrid";
import ResponsiveTableGrid from "../../Components/SmallComponents/TableGrid/ResponsiveTableGrid";
import { useLanguageContext } from "../../context/LanguageContext";
import LoadingBackdrop from "../../Components/MaterialUI/LoadingBackdrop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatDateAndTime,
  handleGetSelectOption,
} from "../../Global/Utils/commonFunctions";
import Select from "../../Components/MaterialUI/FormFields/Select";
import Button from "../../Components/MaterialUI/Button";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useAppSelector } from "../../hooks/useAppSelector";
import { getDetails, setDetails } from "../../redux/Elprom/detailsSlice";
import { useAppDispatch } from "../../hooks/useAppDispatch";
import { getKso, setKso } from "../../redux/Elprom/ksoSlice";
import { getSupco, setSupco } from "../../redux/Elprom/supcoSlice";
import { getPrices, setPrices } from "../../redux/Elprom/pricesSlice";
import { getNotDistributed, setNotDistributed } from "../../redux/Elprom/notDistributedSlice";
import { getPricesPO, setPricesPO } from "../../redux/Elprom/pricesPoSlice";

const TABLE_HEIGHT = 1150;
const INITIAL_ROWS = 25;
const VIRTUAL_COLS = 10;

type ModalType = "compare" | "upload-files" | "download-files" | null;

type TableCols = {
  kso: TableGridColumnSchema[];
  supco: TableGridColumnSchema[];
  prices: TableGridColumnSchema[];
  details: TableGridColumnSchema[];
  notDist: TableGridColumnSchema[];
  pricesPo: TableGridColumnSchema[];
};

type TableRows = {
  kso: Record<string, any>[];
  supco: Record<string, any>[];
  prices: Record<string, any>[];
  details: Record<string, any>[];
  notDist: Record<string, any>[];
  pricesPo: Record<string, any>[];
};

type CompareData = null | {
  cols: TableGridColumnSchema[];
  rows: Record<string, any>[];
};

interface ProjectHubDataPageProps {
  visible: boolean;
}

const ProjectHubDataPage: React.FC<ProjectHubDataPageProps> = ({visible}) => {
  const theme = useTheme();
  const styles = { ...cssSpacingStyles(theme), ...cssLayoutStyles, ...cssFontStyles };
  const location = useLocation();
  const dispatch = useAppDispatch();
  // store
  let details = useAppSelector(getDetails);
  let kso = useAppSelector(getKso);
  let supco = useAppSelector(getSupco);
  let prices = useAppSelector(getPrices);
  let notDistributed = useAppSelector(getNotDistributed);
  let pricesPo = useAppSelector(getPricesPO);

  const [pageStatus, setPageStatus] = useState<FormStatuses>(null);
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const [openModal, setOpenModal] = useState<ModalType>(null);
  const [tabView, setTabView] = useState<number>(location.state?.tab || 0);
  const [filesMeta, setFilesMeta] = useState<ElpromFileMeta[]>([]);

  const [colsData, setColsData] = useState<TableCols | null>(null);
  const [rowsData, setRowsData] = useState<TableRows | null>(null);
  const [datesWithData, setDatesWithData] = useState<SelectOption[]>([]);
  const [selectedDate, setSelectedDate] = useState<string>(
    location.state?.selectedDate || ""
  );
  const [dateChangeLoading, setDateChangeLoading] = useState<boolean>(false);

  const [compareDate, setCompareDate] = useState<string>(
    location.state?.compareDate || ""
  );
  const [isComparing, setIsComparing] = useState<boolean>(!!location.state?.compareDate);
  const [compareData, setCompareData] = useState<CompareData>(null);
  const [detailsTableLoaded, setDetailsTableLoaded] = useState<boolean>(false);
  const [loadOtherTables, setLoadOtherTables] = useState<boolean>(false);
  const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
  const [userAccessPermissions, setUserAccessPermissions] = useState<ElpromUserAccessPermisionData | null>(null);
  const { setAuthedUser } = useAuthedContext();
  const { language, t } = useLanguageContext();
  const navigate = useNavigate();

  const cellBgStaticColor = theme.palette.mode === "dark" ? "#303030" : "whiteSmoke";

  const columnsStaticColors: Record<string, string> = {
    production_site: cellBgStaticColor,
    unit_price: cellBgStaticColor,
    total_value_in_bgn: cellBgStaticColor,
    total_nomenclatures: cellBgStaticColor,
    paint: theme.palette.primary.light,
    need_closure_source_with_overdue_zp: theme.palette.primary.light,
    remaining_stock_on_MS105: theme.palette.primary.light,
    remaining_stock_excluding_MS105: theme.palette.primary.light,
    total_deficit_consumption: theme.palette.secondary.light,
    minimum_deficit_required: theme.palette.secondary.light,
    total_consumption: theme.palette.primary.light,
    norm_per_detail: theme.palette.primary.light,
    base_unit_cooperation: theme.palette.primary.light,
    extended_material_name_cooperation: theme.palette.primary.light,
    cooperation_material: theme.palette.primary.light,
  };

  useEffect(() => {
    (async () => {
      try {
        const userAccessData = await callApi<ElpromUserAccessPermisionData>({
          query: getQueryElpromUserAccess(),
          auth: { setAuthedUser },
        });
  
        setUserAccessPermissions(userAccessData);
        
      } catch (err) {
        setPageStatus("error")
        setAlertMessage(t("Something went wrong"))
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if(!userAccessPermissions) {
      return;
    }
    const firstTrueIndex = TAB_NAMES.findIndex((tabName) => userAccessPermissions[tabName]);
        if (firstTrueIndex !== -1 && detailsTableLoaded) {
          setTabView(firstTrueIndex);
        }
  }, [userAccessPermissions, detailsTableLoaded])
  
  useEffect(() => {
    (async () => {
      try {
        const tab = location?.state?.tab;
        const selected = location?.state?.selectedDate;
        const compare = location?.state?.compareDate;
        if (compare && selected && tab) {
          await handleCompareData();
          await handleFetchTableData(selectedDate);
        }
      } catch (err) {
        console.log(err);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    (async () => {
      try {
        setPageStatus("loading");
        const dates = await callApi<Date[]>({
          query: getQueryElpromDates(),
          auth: { setAuthedUser },
        });
        const sortedDates = dates.sort(
          (a, b) => new Date(b).getTime() - new Date(a).getTime()
        );
        const latestDate = new Date(sortedDates[0]).toISOString();
        await handleDetailsFetch(latestDate);
        await handleFetchFiles();

        setSelectedDate(latestDate);
        setDatesWithData(
          sortedDates.map((item) => ({
            value: new Date(item).toISOString(),
            description: formatDateAndTime(new Date(item).toISOString(), "date"),
          }))
        );

        setPageStatus(null);
        setAlertMessage(null);
        setLoadOtherTables(true);
      } catch (err) {
        console.log("err ", err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  useEffect(() => {
    (async () => {
      try {
        if (detailsTableLoaded && loadOtherTables) {
          await handleFetchTableData(selectedDate);
          setAllDataLoaded(true);
        }
      } catch (err) {
        console.log(err);
        setPageStatus("error");
        setAlertMessage(t("Something went wrong"));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsTableLoaded, loadOtherTables]);

  const getAccessibleTabsList = (): TabName[] => {
    if(!userAccessPermissions) {
      return [];
    }

    return TAB_NAMES.filter(tab => userAccessPermissions[tab] === true)
  }

  const handleOnDateChange = async (val: string) => {
    try {
      setDateChangeLoading(true);
      setAllDataLoaded(false);
      setSelectedDate(val);
      setCompareDate("");
      setIsComparing(false);

      if(!shouldHideTab("collateral_summary")) {
        details = await callApi<ElpromTableData>({
          query: getQueryElpromDetails(language as any, val),
          auth: { setAuthedUser },
        });
      }

      dispatch(setDetails(details));
      setColsData((prev) =>
        prev
          ? {
            ...prev,
            details: shouldHideTab("collateral_summary") ? [] : handleColsData(details.columns, details.rows, columnsStaticColors, theme,
              [
                "collateral_percentage",
                "in_delivery_percentage",
                "non_collateral_percentage",
              ],
              true
            )
          }
          : prev
      );
      setRowsData((prev) =>
        prev
          ? {
            ...prev,
            details: shouldHideTab("collateral_summary") ? [] : handleDetailsRows(details.rows),
          }
          : prev
      );
      setDateChangeLoading(false);

      if(!shouldHideTab("kso")) {
        kso = await callApi<ElpromTableData>({
          query: getQueryElpromKso(language as any, val),
          auth: { setAuthedUser },
        });
        dispatch(setKso(kso));
      }

      if(!shouldHideTab("supco")) {
        supco = await callApi<ElpromTableData>({
          query: getQueryElpromSupco(language as any, val),
          auth: { setAuthedUser },
        });
        dispatch(setSupco(supco));
      }

      if(!shouldHideTab("prices")) {
        prices = await callApi<ElpromTableData>({
          query: getQueryElpromPrices(language as any, val),
          auth: { setAuthedUser },
        });
        dispatch(setPrices(prices));
      }

      if(!shouldHideTab("collateral_not_distributed")) {
        notDistributed = await callApi<ElpromTableData>({
          query: getQueryElpromCollateralNotDistributed(language as any, val),
          auth: { setAuthedUser },
        });
        dispatch(setNotDistributed(notDistributed));
      }

      if(!shouldHideTab("prices_po")) {
        pricesPo = await callApi<ElpromTableData>({
          query: getQueryElpromPricesPo(language as any, val),
          auth: { setAuthedUser },
        });
        dispatch(setPricesPO(pricesPo));
      }

      setColsData((prev) =>
        prev
          ? {
            ...prev,
            details: shouldHideTab("collateral_summary") ? [] : handleColsData(details.columns, details.rows, columnsStaticColors, theme,
              [
                "collateral_percentage",
                "in_delivery_percentage",
                "non_collateral_percentage",
              ],
              true
            ),
            notDist: shouldHideTab("collateral_not_distributed") ? [] : handleColsData(notDistributed.columns, notDistributed.rows, columnsStaticColors, theme, [], false),
            kso: shouldHideTab("kso") ? [] : handleColsData(kso.columns, kso.rows, columnsStaticColors, theme, []),
            supco: shouldHideTab("supco") ? [] : handleColsData(supco.columns, supco.rows, columnsStaticColors, theme, []),
            prices: shouldHideTab("prices") ? [] : handleColsData(prices.columns, prices.rows, columnsStaticColors, theme, []),
            pricesPo: shouldHideTab("prices_po") ? [] : handleColsData(pricesPo.columns, pricesPo.rows, columnsStaticColors, theme, [])
          }
          : prev
      );
      setRowsData((prev) =>
        prev
          ? {
            ...prev,
            details: shouldHideTab("collateral_summary") ? [] : handleDetailsRows(details.rows),
            notDist: shouldHideTab("collateral_not_distributed") ? [] : handleNotDistRows(notDistributed.rows),
            kso: shouldHideTab("kso") ? [] : handleDetailsRows(kso.rows),
            supco: shouldHideTab("supco") ? [] : handleDetailsRows(supco.rows),
            prices: shouldHideTab("prices") ? [] : handleDetailsRows(prices.rows),
            pricesPo: shouldHideTab("prices_po") ? [] : handleDetailsRows(pricesPo.rows),
          }
          : prev
      );
    } catch (err) {
      console.log("handleOnDateChange err ", err);
    } finally {
      setDateChangeLoading(false);
      setAllDataLoaded(true);
    }
  };

  const handleCompareData = async () => {
    try {
      if (!selectedDate || !compareDate) {
        return;
      }

      setDateChangeLoading(true);
      const compareDates = await callApi<ElpromTableData>({
        query: getQueryElpromCompareData(language as any, selectedDate, compareDate),
        auth: { setAuthedUser },
      });
      const unequalValues = findUnequalValues(compareDates.rows);
      const diffCells: TableCellsConfig = unequalValues.map((val) => ({
        id: val,
        bgColor:
          theme.palette.mode === "light"
            ? theme.palette.warning.light
            : theme.palette.warning.dark,
      }));

      setCompareData({
        cols: shouldHideTab("collateral_compare") ? [] : handleColsData(
          compareDates.columns,
          compareDates.rows,
          columnsStaticColors,
          theme,
          [
            "collateral_percentage",
            "in_delivery_percentage",
            "non_collateral_percentage",
          ],
          true,
          undefined,
          diffCells
        ),
        rows: shouldHideTab("collateral_compare") ? [] : handleCompareRows(compareDates.rows),
      });

      setOpenModal(null);
      setIsComparing(true);
    } catch (err) {
      console.log("handleCompareData err: ", err);
    }
    setDateChangeLoading(false);
  };

  const handleStopCompare = async () => {
    try {
      if (!selectedDate) {
        return;
      }
      await handleOnDateChange(selectedDate);
      setCompareDate("");
      setIsComparing(false);
    } catch (err) {
      console.log("handleStopCompare err ", err);
    }
  };

  const handleFetchFiles = async () => {
    const meta = await callApi<ElpromFileMeta[]>({
      query: getQueryElpromFilesMetadata(),
      auth: { setAuthedUser },
    });
    setFilesMeta(meta);
  };

  const handleFetchTableData = async (latestDate: string, fetchAgain: boolean = false) => {
    try {
      setAllDataLoaded(false);
      const ksoLimit = 1000;
      const limit = 5000;
      const offset = 0;
      const language_code = language as any;

      if (!shouldHideTab("kso") && (kso.length === 0 || fetchAgain)) {
        kso = await callApi<ElpromTableData>({
          query: getQueryElpromKso({ limit: ksoLimit, offset, language_code }, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setKso(kso));
      }
      if (!shouldHideTab("supco") && (supco.length === 0 || fetchAgain)) {
        supco = await callApi<ElpromTableData>({
          query: getQueryElpromSupco({ limit, offset, language_code }, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setSupco(supco));
      }
      if (!shouldHideTab("prices") && (prices.length === 0 || fetchAgain)) {
        prices = await callApi<ElpromTableData>({
          query: getQueryElpromPrices({ limit, offset, language_code }, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setPrices(prices));
      }

      if (!shouldHideTab("collateral_not_distributed") && (notDistributed.length === 0 || fetchAgain)) {
        notDistributed = await callApi<ElpromTableData>({
          query: getQueryElpromCollateralNotDistributed(language_code, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setNotDistributed(notDistributed));
      }

      if (!shouldHideTab("prices_po") && (pricesPo.length === 0 || fetchAgain)) {
        pricesPo = await callApi<ElpromTableData>({
          query: getQueryElpromPricesPo({ limit, offset, language_code }, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setPricesPO(pricesPo));
      }

      setColsData((prev) => ({
        kso: shouldHideTab("kso") ? [] : handleColsData(kso.columns, kso.rows, columnsStaticColors, theme, []),
        supco: shouldHideTab("supco") ? [] : handleColsData(supco.columns, supco.rows, columnsStaticColors, theme, []),
        prices: shouldHideTab("prices") ? [] : handleColsData(prices.columns, prices.rows, columnsStaticColors, theme, []),
        pricesPo: shouldHideTab("prices_po") ? [] : handleColsData(
          pricesPo.columns,
          pricesPo.rows,
          columnsStaticColors,
          theme,
          []
        ),
        details: shouldHideTab("collateral_summary") ? [] : (prev?.details || []),
        notDist: shouldHideTab("collateral_not_distributed") ? [] : handleColsData(
          notDistributed.columns,
          notDistributed.rows,
          columnsStaticColors,
          theme,
          [],
          false
        ),
      }));
      setRowsData((prev) => ({
        kso: shouldHideTab("kso") ? [] : kso.rows,
        supco: shouldHideTab("supco") ? [] : supco.rows,
        prices: shouldHideTab("prices") ? [] : prices.rows,
        pricesPo: shouldHideTab("prices_po") ? [] : pricesPo.rows,
        details: shouldHideTab("collateral_summary") ? [] : (prev?.details || []),
        notDist: shouldHideTab("collateral_not_distributed") ? [] : handleNotDistRows(notDistributed.rows),
      }));
    } catch (err) {
      console.log("handleOnDateChange err ", err);
    } finally {
      setAllDataLoaded(true);
      setDateChangeLoading(false);
    }
  };

  const handleDetailsFetch = async (latestDate: any, fetchAgain: boolean = false) => {
    setLoadOtherTables(false);
    const language_code = language as any;

    try {
      if (!shouldHideTab("collateral_summary") && (details.length === 0 || fetchAgain)) {
        details = await callApi<ElpromTableData>({
          query: getQueryElpromDetails(language_code, latestDate),
          auth: { setAuthedUser },
        });
        dispatch(setDetails(details));
      }

      setColsData({
        kso: [],
        supco: [],
        prices: [],
        pricesPo: [],
        details: shouldHideTab("collateral_summary") ? [] : handleColsData(
          details.columns,
          details.rows,
          columnsStaticColors,
          theme,
          ["collateral_percentage", "in_delivery_percentage", "non_collateral_percentage"],
          true
        ),
        notDist: [],
      });
      setRowsData({
        kso: [],
        supco: [],
        prices: [],
        pricesPo: [],
        details: shouldHideTab("collateral_summary") ? [] : handleDetailsRows(details.rows),
        notDist: [],
      });
    } catch (error) {
      console.log(error)
    }
  };

  const handleDetailsRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleCompareRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(
                `/GIANT-Planning/project-hub-data/compare/${item.client_order_1}?language=${language}&selectedDate=${selectedDate}&compareDate=${compareDate}`
              );
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const handleNotDistRows = (data: Record<string, any>[]) => {
    return data.map((item) => {
      return {
        ...item,
        actions: (
          <IconButton
            onClick={() => {
              navigate(`/GIANT-Planning/project-hub-data/kso/${item.client_order}`, {});
            }}
            size="small"
            sx={{ marginTop: -1, marginBottom: -1 }}
          >
            <VisibilityIcon />
          </IconButton>
        ),
      };
    });
  };

  const shouldHideTab = (name: string) => {
    if(userAccessPermissions && TAB_NAMES.includes(name)) {
      return !userAccessPermissions[name];
    }
    return false;
  }

  const canUpload = () => {
    if(userAccessPermissions && userAccessPermissions["upload_files"]) {
      return true;
    }
    return false;
  }

  return (
    <Box component="div" sx={{display: visible ? "block" : "none"}}>
      <Stack
        css={[styles.width100, styles.labelBreak]}
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        direction="row"
      >
        <Box component="div" />
        <Typography variant="h2" textAlign="center">
          {t("Collateral")}
        </Typography>

        <Stack direction="row" spacing={1} alignItems="center">
          {canUpload() && <IconButton
            aria-label="sync currencies"
            onClick={() => setOpenModal("upload-files")}
            disabled={pageStatus === "loading"}
          >
            <FileUploadIcon
              sx={{
                color:
                  pageStatus === "loading"
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main,
              }}
            />
          </IconButton>
            }
          <IconButton
            aria-label="sync currencies"
            onClick={() => setOpenModal("download-files")}
            disabled={pageStatus === "loading"}
          >
            <DownloadIcon
              sx={{
                color:
                  pageStatus === "loading"
                    ? theme.palette.text.disabled
                    : theme.palette.primary.main,
              }}
            />
          </IconButton>
        </Stack>
      </Stack>

      <Alert
        css={[styles.widthLimit20, styles.labelBreak]}
        message={alertMessage || ""}
        showAlert={!!alertMessage}
        severity={pageStatus}
      />

      <Stack alignItems={"center"} justifyContent="center">
        <Box sx={{ width: { xs: "100%", md: "auto" } }} component="div">
          <Tabs
            css={styles.contentBreak}
            sx={{ overflowX: "auto" }}
            value={tabView}
            onChange={(_: any, val: React.SetStateAction<number>) => setTabView(val)}
            aria-label="configure tabs"
            variant="scrollable"
            scrollButtons="auto"
          >
            {!shouldHideTab("collateral_summary") && <Tab label={t("Collateral Summary")} value={0}/>}
            {!shouldHideTab("supco") && <Tab label="Supco" value={1}/>}
            {!shouldHideTab("kso") && <Tab label="Kso" value={2}/>}
            {!shouldHideTab("prices") && <Tab label={t("Prices")} value={3}/>}
            {!shouldHideTab("prices_po") && <Tab label={t("Prices PO")} value={4}/>}
            {!shouldHideTab("collateral_not_distributed") && <Tab label={t("Collateral Not Distributed")} value={5}/>}
            {!shouldHideTab("collateral_compare") && <Tab label={t("Collateral Compare")} value={6}/>}
          </Tabs>
        </Box>
      </Stack>

      {pageStatus === "loading" ? (
        <Box css={styles.width100} sx={{ height: "10rem" }} component="div">
          <LoadingBackdrop loading={pageStatus === "loading"} />
        </Box>
      ) : rowsData && colsData ? (
        <>
          {tabView !== 6 ? (
            <Stack
              css={styles.labelBreak}
              direction="row"
              spacing={3}
              alignItems="center"
              justifyContent="center"
            >
              <Select
                css={[styles.width100, styles.widthLimit10]}
                label={t("Date")}
                selectOptions={datesWithData}
                value={selectedDate}
                onChange={(e) => handleOnDateChange(e.target.value)}
                disabled={
                  dateChangeLoading ||
                  !datesWithData?.length ||
                  !detailsTableLoaded ||
                  !loadOtherTables ||
                  !allDataLoaded
                }
              />
            </Stack>
          ) : null}

          {tabView === 0 ? (
            <Box component="div">
              <ResponsiveTableGrid
                rows={rowsData.details}
                colSchema={colsData.details}
                responsive="desktop"
                configuration={{
                  density: "compact",
                  initialRowsPerPage: INITIAL_ROWS,
                  columnVisibility: {
                    id: false,
                    project_lines: false,
                    incomming_control_value_in_bgn: false,
                    available_on_stock_value_in_bgn: false,
                    placed_po_nomenclatures: false,
                    placed_po_value_in_bgn: false,
                    expedition: false,
                    project_lines_1: false,
                    project_lines_2: false,
                    client_order_1: false,
                  },
                  enableStickyHeader: true,
                  maxTableHeight: TABLE_HEIGHT,
                  grouping: ["priority"],
                  columnPinning: {
                    right: ["actions"],
                    left: [],
                  },
                }}
                tableID="ProjectHubDataPage_Collateral_Summary_table"
                backdropLoading={dateChangeLoading}
                onLoadingConfigState={() => setDetailsTableLoaded(true)}
                virtualizedColsNumber={VIRTUAL_COLS}
              />
            </Box>
          ) : null}
          {tabView === 1 ? (
            <>
              {!colsData?.supco?.length ? (
                <Alert
                  css={[styles.widthLimit20, styles.labelBreak]}
                  message={t("Fetching Data...")}
                  showAlert={true}
                  severity={"loading"}
                />
              ) : (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={rowsData.supco}
                    colSchema={colsData.supco}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: { id: false },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                    }}
                    tableID="ProjectHubDataPage_Supco_table"
                    backdropLoading={!allDataLoaded}
                    virtualizedColsNumber={VIRTUAL_COLS}
                  />
                </Box>
              )}
            </>
          ) : null}

          <Box sx={{ display: tabView === 2 ? "block" : "none" }} component="div">
            <ResponsiveTableGrid
              rows={rowsData.kso}
              colSchema={colsData.kso}
              responsive="desktop"
              configuration={{
                density: "compact",
                initialRowsPerPage: INITIAL_ROWS,
                columnVisibility: { id: false },
                enableStickyHeader: true,
                maxTableHeight: TABLE_HEIGHT,
              }}
              tableID="ProjectHubDataPage_Kso_table"
              backdropLoading={!allDataLoaded}
              virtualizedColsNumber={VIRTUAL_COLS}
            />
          </Box>

          {tabView === 3 ? (
            <>
              {!colsData?.prices?.length ? (
                <Alert
                  css={[styles.widthLimit20, styles.labelBreak]}
                  message={t("Fetching Data...")}
                  showAlert={true}
                  severity={"loading"}
                />
              ) : (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={rowsData.prices}
                    colSchema={colsData.prices}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: { id: false },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                    }}
                    tableID="ProjectHubDataPage_Prices_table"
                    backdropLoading={!allDataLoaded}
                    virtualizedColsNumber={VIRTUAL_COLS}
                  />
                </Box>
              )}
            </>
          ) : null}

          {tabView === 4 ? (
            <>
              {!colsData?.pricesPo?.length ? (
                <Alert
                  css={[styles.widthLimit20, styles.labelBreak]}
                  message={t("Fetching Data...")}
                  showAlert={true}
                  severity={"loading"}
                />
              ) : (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={rowsData.pricesPo}
                    colSchema={colsData.pricesPo}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: { id: false },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                    }}
                    tableID="ProjectHubDataPage_Prices_po_table"
                    backdropLoading={!allDataLoaded}
                    virtualizedColsNumber={VIRTUAL_COLS}
                  />
                </Box>
              )}
            </>
          ) : null}

          {tabView === 5 ? (
            <>
              {!colsData?.notDist?.length ? (
                <Alert
                  css={[styles.widthLimit20, styles.labelBreak]}
                  message={t("Fetching Data...")}
                  showAlert={true}
                  severity={"loading"}
                />
              ) : (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={rowsData.notDist}
                    colSchema={colsData.notDist}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: { id: false },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                      grouping: ["category"],
                    }}
                    tableID="ProjectHubDataPage_Collateral_not_Distributed_table"
                    backdropLoading={!allDataLoaded}
                    virtualizedColsNumber={VIRTUAL_COLS}
                  />
                </Box>
              )}
            </>
          ) : null}

          {tabView === 6 ? (
            <>
              <Stack
                css={styles.labelBreak}
                direction="row"
                spacing={3}
                alignItems="center"
                justifyContent="center"
              >
                <Select
                  css={[styles.width100, styles.widthLimit10]}
                  label={t("Date")}
                  selectOptions={datesWithData.filter(
                    (item) => item.value !== compareDate
                  )}
                  value={selectedDate}
                  onChange={(e) => handleOnDateChange(e.target.value)}
                  disabled={dateChangeLoading || !datesWithData?.length}
                />
                {compareDate && isComparing ? (
                  <Stack direction="row" alignItems="center" spacing={3}>
                    <CompareArrowsIcon />
                    <Select
                      css={[styles.width100, styles.widthLimit10]}
                      label={t("Compare Against")}
                      selectOptions={datesWithData.filter(
                        (item) => item.value !== selectedDate
                      )}
                      value={compareDate}
                      onChange={(e) => setCompareDate(e.target.value)}
                      disabled={dateChangeLoading || !datesWithData?.length}
                    />
                    <IconButton
                      onClick={handleStopCompare}
                      disabled={dateChangeLoading || !datesWithData?.length}
                    >
                      <DeleteOutlineIcon color="error" />
                    </IconButton>
                  </Stack>
                ) : (
                  <Button
                    variant="text"
                    color="secondary"
                    onClick={() => setOpenModal("compare")}
                  >
                    Compare
                  </Button>
                )}
              </Stack>

              {isComparing && compareData ? (
                <Box component="div">
                  <ResponsiveTableGrid
                    rows={compareData.rows}
                    colSchema={compareData.cols}
                    responsive="desktop"
                    configuration={{
                      density: "compact",
                      initialRowsPerPage: INITIAL_ROWS,
                      columnVisibility: {
                        id: false,
                        project_lines: false,
                        incomming_control_value_in_bgn: false,
                        available_on_stock_value_in_bgn: false,
                        placed_po_nomenclatures: false,
                        placed_po_value_in_bgn: false,
                        expedition: false,
                        project_lines_1: false,
                        project_lines_2: false,
                        client_order_1: false,
                      },
                      columnPinning: {
                        right: ["actions"],
                        left: [],
                      },
                      enableStickyHeader: true,
                      maxTableHeight: TABLE_HEIGHT,
                    }}
                    tableID="ProjectHubDataPage_Collateral_Compare_table"
                    backdropLoading={!allDataLoaded}
                    virtualizedColsNumber={VIRTUAL_COLS}
                  />
                </Box>
              ) : null}
            </>
          ) : null}
        </>
      ) : null}

      <Modal
        open={!!openModal}
        onClose={() => setOpenModal(null)}
        fullWidth
        maxWidth={(openModal === "upload-files" || openModal === "download-files") ? "md" : "sm"}
      >

        {openModal === "upload-files" ? (
          <ElpromUploadFilesModal
            filesMeta={filesMeta}
            handleFetchTableData={async () => {
              await handleDetailsFetch(selectedDate, true);
              await handleFetchTableData(selectedDate, true);
              await handleFetchFiles();
              setOpenModal(null);
            }}
          />
        ) : null}

        {openModal === "download-files" ? (
          <ElpromDownloadFilesModal
            filesMeta={filesMeta}
            initialCompareDate={compareDate || ""}
            initialSelectedDate={selectedDate || ""}
            datesWithData={datesWithData}
            clientOrderOptions={handleGetSelectOption(
              (rowsData?.details || []).map((row) => row.client_order)
            )}
            accessibleTabs={getAccessibleTabsList()}
          />
        ) : null}

        {openModal === "compare" ? (
          <Stack spacing={2}>
            <Typography variant="body1">
              {t("Select a date and compare it against")}{" "}
              {selectedDate ? formatDateAndTime(selectedDate, "date") : ""}
            </Typography>

            <Select
              css={[styles.width100, styles.widthLimit15]}
              label={t("Compare Against")}
              selectOptions={datesWithData.filter((item) => item.value !== selectedDate)}
              value={compareDate}
              onChange={(e) => setCompareDate(e.target.value)}
              disabled={dateChangeLoading || !datesWithData?.length}
            />

            <Stack alignItems={"center"} justifyContent={"center"}>
              <Button onClick={handleCompareData} disabled={!compareDate}>
                {t("Confirm and Compare")}
              </Button>
            </Stack>
          </Stack>
        ) : null}
      </Modal>
    </Box>
  );
};

export default ProjectHubDataPage;

const aggregations = [TableAggregationFns.sum];
const aggAndAvg = [TableAggregationFns.mean];

const handleColsData = (
  cols: ElpromTableDataCols[],
  rows: Record<string, any>[],
  columnsStaticColors: Record<string, string>,
  theme: Theme,
  colsWithAverage: string[],
  withActions?: boolean,
  width?: number,
  cellsConfig?: TableCellsConfig
): TableGridColumnSchema[] => {
  const action = { id: "actions", label: "Actions", type: "button" };
  const result: TableGridColumnSchema[] = cols.map((item) => {
    const isNumb = item.type === "number";
    const hasAvg = colsWithAverage.includes(item.id);

    return {
      id: item.id,
      label: item.name,
      type: item.type,
      colBgColor: columnsStaticColors[item.id],
      formatNumb: true,
      aggregationFn: isNumb ? (hasAvg ? aggAndAvg : aggregations) : undefined,
      footerAggregations: isNumb
        ? calcTableFooterAggregations(
          hasAvg ? aggAndAvg : aggregations,
          rows.map((row) => row[item.id]),
          item.decimal_points
        )
        : undefined,
      dynamicBgColor: dynamicBgColors(item, theme),
      ...(width && { width: width }),
      alignCol: item.alignment,
      formatNumbDecimals: item.decimal_points,
      symbol: {
        align: item.symbol_alignment === "before" ? "left" : "right",
        value: item.symbol || "",
      },
      cellsConfig,
    };
  });

  if (withActions) {
    return [...result, action] as TableGridColumnSchema[];
  } else {
    return result as TableGridColumnSchema[];
  }
};

const dynamicBgColors = (cols: ElpromTableDataCols, theme: Theme) => {
  const operation: TableDynamicCondition = {
    operation: "always",
    cellValue: 0,
  };

  if (cols.id === "collateral_percentage") {
    return {
      ...operation,
      bgColor: theme.palette.success.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "in_delivery_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.warning.main,
      partialFillBasedOnVal: true,
    };
  }
  if (cols.id === "non_collateral_percentage") {
    return {
      ...operation,

      bgColor: theme.palette.error.main,
      partialFillBasedOnVal: true,
    };
  }

  return undefined;
};

const findUnequalValues = (rows: Record<string, any>[]): string[] => {
  const unequalIds: string[] = [];

  rows.forEach((item, index) => {
    if (item.total_nomenclatures_1 !== item.total_nomenclatures_2) {
      unequalIds.push(`${index}_total_nomenclatures_1`);
    }
    if (item.total_value_in_bgn_1 !== item.total_value_in_bgn_2) {
      unequalIds.push(`${index}_total_value_in_bgn_1`);
    }
    if (item.priority_1 !== item.priority_2) {
      unequalIds.push(`${index}_priority_1`);
    }
    if (item.client_order_1 !== item.client_order_2) {
      unequalIds.push(`${index}_client_order_1`);
    }
    if (item.project_lines_1 !== item.project_lines_2) {
      unequalIds.push(`${index}_project_lines_1`);
    }
    if (item.collateral_percentage_1 !== item.collateral_percentage_2) {
      unequalIds.push(`${index}_collateral_percentage_1`);
    }
    if (item.in_delivery_percentage_1 !== item.in_delivery_percentage_2) {
      unequalIds.push(`${index}_in_delivery_percentage_1`);
    }
    if (item.non_collateral_percentage_1 !== item.non_collateral_percentage_2) {
      unequalIds.push(`${index}_non_collateral_percentage_1`);
    }
    if (
      item.incomming_control_nomenclatures_1 !== item.incomming_control_nomenclatures_2
    ) {
      unequalIds.push(`${index}_incomming_control_nomenclatures_1`);
    }
    if (item.total_nomenclatures_1 !== item.total_nomenclatures_2) {
      unequalIds.push(`${index}_total_nomenclatures_1`);
    }
    if (item.incomming_control_value_in_bgn_1 !== item.incomming_control_value_in_bgn_2) {
      unequalIds.push(`${index}_incomming_control_value_in_bgn_1`);
    }
    if (
      item.available_on_stock_nomenclatures_1 !== item.available_on_stock_nomenclatures_2
    ) {
      unequalIds.push(`${index}_available_on_stock_nomenclatures_1`);
    }
    if (
      item.available_on_stock_value_in_bgn_1 !== item.available_on_stock_value_in_bgn_2
    ) {
      unequalIds.push(`${index}_available_on_stock_value_in_bgn_1`);
    }
    if (item.placed_po_nomenclatures_1 !== item.placed_po_nomenclatures_2) {
      unequalIds.push(`${index}_placed_po_nomenclatures_1`);
    }
    if (item.placed_po_value_in_bgn_1 !== item.placed_po_value_in_bgn_2) {
      unequalIds.push(`${index}_placed_po_value_in_bgn_1`);
    }
    if (item.not_purchased_nomenclatures_1 !== item.not_purchased_nomenclatures_2) {
      unequalIds.push(`${index}_not_purchased_nomenclatures_1`);
    }
    if (item.not_purchased_value_in_bgn_1 !== item.not_purchased_value_in_bgn_2) {
      unequalIds.push(`${index}_not_purchased_value_in_bgn_1`);
    }
    if (item.client_desired_ship_date_1 !== item.client_desired_ship_date_2) {
      unequalIds.push(`${index}_client_desired_ship_date_1`);
    }
    if (item.expedition_1 !== item.expedition_2) {
      unequalIds.push(`${index}_expedition_1`);
    }
    if (item.latest_delivery_date_placed_po_1 !== item.latest_delivery_date_placed_po_2) {
      unequalIds.push(`${index}_latest_delivery_date_placed_po_1`);
    }
    if (
      item.latest_delivery_date_not_purchased_1 !==
      item.latest_delivery_date_not_purchased_2
    ) {
      unequalIds.push(`${index}_latest_delivery_date_not_purchased_1`);
    }
    if (item.production_ready_date_1 !== item.production_ready_date_2) {
      unequalIds.push(`${index}_production_ready_date_1`);
    }
  });

  return unequalIds;
};
