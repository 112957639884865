import { useEffect, useState } from "react";
import {
  Typography,
  Grid,
  Stack,
  PaletteMode,
  Box,
  Badge,
  IconButton,
  Avatar,
  Tooltip,
  Theme,
} from "@mui/material";
import { useAuthedContext } from "../../context/AuthContext";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { css } from "@emotion/react";
import ContentBox from "../../Components/MaterialUI/ContentBox";
import cssLayoutStyles from "../../Global/Styles/layout";
import LabelWithBoldedPart from "../../Components/MaterialUI/LabelWithBoldedPart";
import cssSpacingStyles from "../../Global/Styles/spacing";
import { useCustomThemeProviderContext } from "../../context/ThemeContext";
import useTheme from "@mui/material/styles/useTheme";
import Switch from "../../Components/MaterialUI/FormFields/Switch";
import Checkbox from "../../Components/MaterialUI/FormFields/Checkbox";
import SelectChartColorPalette from "../../Components/SmallComponents/ChartColorPalette/SelectChartColorPalette";
import EditIcon from "@mui/icons-material/Edit";
import Modal from "../../Components/MaterialUI/Modal";
import { ProfilePictureModalLayout } from "../../Components/PageComponents/Profile/ProfilePictureModalLayout";
import { useTranslateFields } from "../../Global/Hooks/useTranslations";
import { useLanguageContext } from "../../context/LanguageContext";
import DeputyList from "./Components/DeputyList";
import { IS_ELPROM_ENV } from "../../envConfig";

const cssStyles = (theme: Theme) => ({
  accountIcon: css({
    fontSize: "8rem",
    color: "gray",
  }),
  accountImage: css({
    width: "8rem",
    height: "8rem",
    border: "3px solid " + theme.palette.grey[800],
  }),
  boxPreferences: css({
    justifyContent: "space-between",
    alignItems: "center",
  }),
  editProfilePicture: css({
    color: theme.palette.common.white,
    backgroundColor: theme.palette.grey[300],
    borderRadius: "5px",
  }),
  flexCenterVertical: css({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  }),
});

interface UserProfileData {
  value: string | undefined;
  label: string;
}

const Profile = () => {
  const { t } = useLanguageContext();
  const theme = useTheme();
  const styles = { ...cssStyles(theme), ...cssLayoutStyles, ...cssSpacingStyles(theme) };
  const { authedUser, profilePicture } = useAuthedContext();
  const { themeColor, setThemeColor } = useCustomThemeProviderContext();

  const [openInfo, setOpenInfo] = useState<boolean>(false);
  const userProfileData: UserProfileData[] = [
    {
      value: authedUser?.given_name,
      label: "First Name",
    },
    {
      value: authedUser?.family_name,
      label: "Last Name",
    },
    {
      value: authedUser?.user_name,
      label: "Username",
    },
    {
      value: authedUser?.user_name,
      label: "Email",
    },
    {
      value: authedUser?.title,
      label: "Title",
    },
    {
      value: authedUser?.position,
      label: "Position",
    },
  ];

  const handleToggleDarkMode = () => {
    setThemeColor((prevThemeColor: PaletteMode) =>
      prevThemeColor === "light" ? "dark" : "light"
    );
  };

  const handleToggleNotification = (notification: "dashboard" | "sms" | "email") => {
    // TODO: implement logic to store chosen notification option
    console.log(notification);
  };

  useEffect(() => {
    localStorage.setItem("themeColor", themeColor);
  }, [themeColor]);

  return (
    <>
      <ContentBox css={[styles.topBottomPadding3, styles.sectionBreak]}>
        <Stack direction="column" css={styles.flexCenter}>
          <Badge
            sx={{ mb: "1rem" }}
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <Tooltip title={t("Edit Profile")}>
                <IconButton onClick={() => setOpenInfo(true)} disableRipple>
                  <EditIcon css={styles.editProfilePicture} />
                </IconButton>
              </Tooltip>
            }
          >
            {profilePicture.url ? (
              <Avatar
                css={styles.accountImage}
                src={profilePicture.url}
                alt={`${authedUser?.given_name} ${authedUser?.family_name}`}
              />
            ) : (
              <AccountCircleIcon css={styles.accountIcon} />
            )}
          </Badge>

          <Modal
            open={openInfo}
            fullWidth
            label={t("Edit Profile")}
            onClose={() => setOpenInfo(false)}
          >
            <ProfilePictureModalLayout />
          </Modal>

          <Typography variant="h2" gutterBottom>
            {authedUser?.title} {authedUser?.given_name} {authedUser?.family_name}
          </Typography>
          <Typography variant="h4" style={theme.customizedTextStyles.labelStyles}>
            {authedUser?.user_name}
          </Typography>
        </Stack>

        <Stack
          css={[styles.reverseSectionBreak, styles.marginHorizontalAuto]}
          maxWidth="60rem"
          direction="column"
          justifyContent="center"
          alignItems="flex-start"
        >
          <Typography component="h4" variant="h3" css={styles.labelBreak}>
            {t("Account Details")}
          </Typography>

          <Grid container spacing={1.5}>
            {useTranslateFields(userProfileData, ["label"]).map(
              (item: UserProfileData) => (
                <Grid item xs={12} sm={6} key={item.label}>
                  <LabelWithBoldedPart text={item.label} bolded={item.value as string} />
                </Grid>
              )
            )}
          </Grid>

          <Typography
            css={[styles.labelBreak, styles.reverseSectionBreak]}
            component="h4"
            variant="h3"
          >
            {t("Account Preferences")}
          </Typography>

          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <Typography variant="body1">{t("Theme Color")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <Switch
                label={themeColor === "light" ? "Light Mode" : "Dark Mode"}
                onChange={handleToggleDarkMode}
                checked={themeColor === "light" ? false : true}
                sx={{ color: theme.palette.customColors.greyText }}
              />
            </Grid>

            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <Typography variant="body1">{t("Charts Color Palette")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <SelectChartColorPalette />
            </Grid>

            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <Typography variant="body1">{t("Notifications")}</Typography>
            </Grid>
            <Grid item xs={12} sm={6} css={styles.flexCenterVertical}>
              <Box component="div">
                <Checkbox
                  label={t("Dashboard")}
                  onChange={() => handleToggleNotification("dashboard")}
                />
                <Checkbox
                  label={t("Email")}
                  onChange={() => handleToggleNotification("email")}
                />
                <Checkbox label="SMS" onChange={() => handleToggleNotification("sms")} />
              </Box>
            </Grid>
          </Grid>
        </Stack>
      </ContentBox>

      {IS_ELPROM_ENV ? (
        <ContentBox css={styles.topBottomPadding3}>
          <DeputyList />
        </ContentBox>
      ) : null}
    </>
  );
};

export default Profile;
